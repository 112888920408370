import Vue from 'vue';
import HasPlans from '../mixins/HasPlans';
import { servicePlanFeatures } from '@/modules/service-plan/tools/enums';
import radarsImage from '@/assets/component/plan-list-table-radars.png';
import { scrollToElement } from '@/utils/helpers';

export default Vue.extend({
  name: 'PlanListTable',

  mixins: [HasPlans],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Object],
      default: undefined,
    },
    showTopActions: {
      type: Boolean,
      default: false,
    },
    showCustomPlan: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      servicePlanFeatures,
      radarsImage,
    };
  },

  computed: {
    items() {
      return this.planListItemsTable;
    },
    //TODO: Consider change to selected plan level instead of whole object
    selectedPlanIndex: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    choosenLevel() {
      return this.value?.level || undefined;
    },
    checkSize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 24
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 20
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        return 16
      }

       return  12;
    },
  },

  methods: {
    choosePlan(plan) {
      this.$emit('input', plan);
    },
    scrollToElement,
  },
});
